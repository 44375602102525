:local {
  $breakpoint-mobile: 767;

  .columns {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint-mobile + 'px') {
      flex-wrap: wrap;
    }
  }

  .column {
    padding: 0 1em;

    &.half {
      width: 50%;

      @media (max-width: $breakpoint-mobile + 'px') {
        width: 100%;
      }
    }

    &.noSpacing {
      padding: 0;
    }
  }
}
