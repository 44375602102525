:local {
  .choice {
    display: flex;
    align-items: center;
    line-height: 1em;
    position: relative;
    vertical-align: middle;

    > input {
      position: absolute;
      left: -9999px;
      visibility: hidden;
    }
  }

  .label {
    cursor: pointer;
    display: block;
    color: #101010;
    font-weight: 400;
    font-size: 1em;
    margin: 0 0 0 0.5em;
  }

  .widget {
    > label {
      background: #fff;
      cursor: pointer;
      display: block;
      border: 1px solid #d1d1d1;
      border-radius: 50%;
      height: 26/16 * 1em;
      width: 26/16 * 1em;
      position: relative;

      &:after {
        background: var(--color-selected);
        border-radius: 50%;
        content: '';
        height: 0;
        transition: height 0.25s cubic-bezier(0.6, 0.04, 0.98, 0.335), width 0.25s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        width: 0;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }

    > input {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }


    input:checked + label:after {
      height: 0.75em;
      width: 0.75em;
    }
  }
}
