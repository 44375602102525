:local {
  .typography {
    --font-family-heading: serif;

    &.isCentered {
      text-align: center;
    }

    &.__inverted {
      color: #fff;
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: var(--font-family-heading);
    }

    h1 {
      color: var(--color-h1);
      font-size: 32/16 + em;
      font-weight: 700;
      line-height: 1.3;

      .__inverted & {
        color: #fff;
      }

      strong {
        color: var(--colorStrong);
        font-weight: 800;
      }
    }

    p {
      font-size: 1em;
      font-weight: 300;
      line-height: 1.6;
      margin: 1em 0;
    }
  }
}
