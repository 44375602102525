:local {
    .layers {
        position: fixed;
        z-index: 9999;

        &,
        .layer {
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }
    }

    .layer {
        position: absolute;
    }
}
