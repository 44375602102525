:local {
  $breakpoint-mobile: 767px;

  .masthead {
    background: var(--color-background);
    overflow: hidden;
  }

  .message {
    display: flex;
    flex-direction: column;
    float: right;
    padding-right: 54/16 + em;
    margin: 2.5em 0;
    max-width: 580px;

    @media (max-width: $breakpoint-mobile) {
      align-items: center;
      float: none;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  .countdownContainer {
    flex-basis: 100%;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 0.5em;
    span,
    div {
      font-weight: normal;
    }
  }

  .messageDescription {
    line-height: 1.5em;
    color: var(--color-content-background);
  }

  .logo {
    margin-bottom: 20/16 + em;
    text-align: left;

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
    }

    img {
      display: inline-block;
      height: auto;
      width: 72%;
    }
  }

  .video {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: relative;
    height: 100%;
    min-height: 480px;

    iframe {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .videoModal {
    background: #fff;
    padding-bottom: 9/16 * 100%;
    position: relative;
    max-width: 100%;
    width: 80vw;

    &:before {
      content: '';
    }

    iframe {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .playButton {
    display: block;
    height: 100 / 16 + em;
    width: 100 / 16 + em;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 2;

    @media (max-width: $breakpoint-mobile) {
      flex-wrap: wrap;
      top: 0;
      left: 50%;
    }
  }

  .videoThumbnail {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;

    > canvas,
    > img{
      display: block;
      height: 100%;
      width: auto;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;

      @media all and (min-width: 1280px) {
        width: 100%;
        height: auto;
      }
    }
  }

  .thumbnailText {
    font-size: 23/16+em;
    font-weight: bold;
    position: absolute;
    bottom: 40/23+em;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
    color: #fff;

    span {
      font-family: var(--font-family-heading);
      font-size: 30/16+em;
    }
  }
}
