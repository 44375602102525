.StripeForm .card-errors {
    height: 20px;
    padding: 4px 0;
}

.StripeForm .form-row {
    flex: 2;
}

form.StripeForm {
    display: block;
    width: 60%;
    margin: 0 auto;
}

.StripeForm button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    outline: none;
    text-decoration: none;
    color: #fff;
    background: #32325d;
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    width: 100%;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.025em;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin: 15px auto;
}

.StripeForm .hidden {
    display: none;
}

.spinner,.spinner:after,.spinner:before {
    border-radius: 50%
}

.spinner {
    color: #fff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0 auto;
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 0 2px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.spinner:after,.spinner:before {
    position: absolute;
    content: ""
}

.spinner:before {
    height: 20.4px;
    border-radius: 20.4px 0 0 20.4px;
    top: -.2px;
    left: -.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after,.spinner:before {
    width: 10.4px;
    background: #32325d;
}

.spinner:after {
    height: 10.2px;
    border-radius: 0 10.2px 10.2px 0;
    top: -.1px;
    left: 10.2px;
    -webkit-transform-origin: 0 10.2px;
    transform-origin: 0 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}
