:local {
  .top {
    background: var(--color-background);
    color: var(--color-text);
    font-size: 14/16 + em;
    padding: 16/14 + em;
    text-align: center;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .divider {
    background: var(--color-text);
    display: inline-block;
    height: 1em;
    margin: 0 .25em;
    vertical-align: middle;
    width: 1px;
  }
}
