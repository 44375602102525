:local {
  .footer {
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 12.5% 1em 6.5%;
    text-align: center;

    h1 {
      color: #fff;
      font-family: var(--font-family-message);
      font-size: 34/16 + em;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 0 3em;
    }
  }

  .logo {
    display: inline-block;
    height: auto;
    max-width: 320px;
  }
}
