:local {
  --bg-color: pink;

  .footer {
    background: var(--bg-color);
    color: #fff;
    font-size: 14/16 + em;
    padding: 0 1em;
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .divider {
    background: #fff;
    display: inline-block;
    height: 1em;
    margin: 0 .25em;
    vertical-align: middle;
    width: 1px;
  }

  .disclaimer {
    padding: 1.25em 0;
    font-size: 0.75em;
  }
}
