:local {
  .header {
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #fff;
    padding: 17% 1em 6.5%;
    text-align: center;

    h1 {
      font-family: var(--font-family-message);
      font-size: 34/16 + em;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 0 1em;
    }
  }
}
