:local {
  .buttons {
    display: flex;
    margin: -.5em;
    position: relative;
    flex-wrap: wrap;
    width: 100%;

    &[error-message] {
      outline: 1px solid var(--color-error);

      &:after {
        content: attr(error-message);
        background: var(--color-error);
        color: #FFF;
        font-size: .85em;
        padding: .1em .5em;
        position: absolute;
        right: 1 / .85 + em;
        top: 100%;
        z-index: 9;
      }
    }
  }

  .button {
    flex-grow: 1;
    overflow: hidden;
    margin: .5em;
    position: relative;

    @media (max-width: 580px) {
      flex-basis: 33.33%;
    }

    > input[type="radio"] {
      display: block;
      opacity: 0;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      &:checked + label {
        background: var(--color-selected);
        border-color: var(--color-selected);
        color: #fff;
        font-weight: 600;
      }
    }

    label {
      border-radius: 3px;
      border: 1px solid var(--color-selected);
      color: var(--color-selected);
      cursor: pointer;
      display: block;
      padding: .75em .25em;
      text-align: center;
      width: 100%;
      font-weight: 600;
    }
  }
}
