:local {
  .testimonials {
    overflow: hidden;
    max-width: 480px;
    margin: 50/16 + em auto 0;

    h3 {
      color: #9d9d9d;
      font-weight: 300;
      font-size: 1em;
      line-height: 1.3;
      margin: 1em;
      text-align: center;
    }

    /** Disable flip shadows **/
    :global {
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        display: none !important;
      }
    }
  }

  .container {
    margin: 0 -20/16 + em 0;
    padding-bottom: 1em;
  }

  .testimonial {
    font-size: var(--testimonial-font-size);
    margin: 0 20/16 + em;

    blockquote {
      background: var(--testimonial-background-color);
      color: var(--testimonial-color);
      font-style: var(--testimonial-font-style);
      font-weight: var(--testimonial-font-weight);
      line-height: 1.5;
      text-align: center;
      margin: 0 0 20/16 + em 0;
      padding: 32/18 + em;
      position: relative;

      &:after {
        content: '';
        border-left: 20/16 + em solid transparent;
        border-right: 20/16 + em solid transparent;
        border-top: 20/16 + em solid var(--testimonial-background-color);
        margin-left: -10/16 + em;
        height: 0;
        width: 0;
        position: absolute;
        top: 100%;
        left: 50%;
      }
    }
  }

  .author {
    color: #111;
    font-size: 14/16 + em;
    margin-top: 1.7 + em;
    text-align: center;

    .authorName {
      color: var(--author-color);
      font-size: 16/14 + em;
      font-weight: 600;
    }
  }

  .pagination {
    text-align: center;
    margin-bottom: 2 + em;

    :global {
      .swiper-pagination-bullet {
        margin: 0.25 + em;
      }

      .swiper-pagination-bullet-active {
        background-color: var(--pagination-bullet-color);
      }
    }
  }
}
