:local {
  .top {
    background: #fff;
    color: #000;
    font-size: 14/16 + em;
    padding: 22/14 + em;
    text-align: center;

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .logo {
    display: inline-block;
    height: auto;
    max-width: 180px;
  }
}
