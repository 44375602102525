:local {
  .heading {
    background: #F9F9F9;
    color: var('--heading-color');
    font-family: var(--font-family-value), serif;
    font-size: 45/16 + em;
    font-weight: 700;
    margin: 0;
    padding: 10/16 + em 1em 0;
    text-align: center;
  }

  .stats {
    background-color: var(--background-color);
    overflow: hidden;
    padding: 10/16 + em 1em 0;
    text-align: center;

    &:after {
      content: '';
      background-image: linear-gradient(to bottom, #F9F9F9 0%, #F9F9F900 20%), var(--background-image);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      display: block;
      margin: 0 -1em;
      height: var(--background-image-height);
      width: calc(100% + 2em);
    }
  }

  .item {
    margin: 50/16 + em auto;
    position: relative;
    max-width: 320px;

    &:after:not(last-child) {
      content: '';
      background: #606060;
      height: 30/16 + em;
      width: 2px;
      position: absolute;
      top: 100%;
    }

    @media (max-width: 969px) {
      font-size: 16px;
    }

    &:after {
      background: #b6b6b6;
      content: '';
      height: 44/16 + em;
      margin-left: -1px;
      width: 2px;
      position: absolute;
      top: calc(100% + .25em);
      left: 50%;
    }

    &:last-child:after {
      display: none;
    }
  }

  .strong {
    font-weight: bold !important;
  }

  .value {
    color: var(--color-value);
    display: block;
    font-family: var(--font-family-value), serif;
    font-size: 38/16 + em;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    &.isHighlighted {
      color: var(--color-value-highlight);
      font-size: var(--size-value-highlight);
      font-weight: 700;
    }
  }

  .label {
    line-height: 1.6;
    margin-top: 12/16 + em;
  }

  .description {
    background: #2c8992;
    color: #fff;
    font-size: 20/16 + em;
    line-height: 1.5;
    padding: 2em;
    text-align: center;

    .descriptionContent {
      margin: 0 auto;
      max-width: 400px;
    }

    p {
      margin: 0;
    }
  }
}
