:local {
  $breakpoint-mobile: 767px;

  .formWrapper {
    overflow: hidden;
    margin: 2em 0 15/16 + em;
    padding: 0 1em;
    width: 66%;

    @media (max-width: 969px) {
      width: 100%;
    }
  }

  .sidebar {
    width: 34%;

    @media (max-width: 969px) {
      width: 100%;
    }
  }
}
