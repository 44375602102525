:local {
  .icons {
    display: flex;

    > a {
      display: block;
      margin: 0 .5em;

      > img {
        display: block;
        height: auto;
        width: 24/16 + em
      }
    }
  }
}
