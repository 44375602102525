@import "src/themes/__variables.scss";

:local {
    .countdown {
        font-weight: 100;
        letter-spacing: 0;

        display: flex;
        padding: .25em 0;
        font-family: 'Oxygen', sans-serif;

        @media all and (max-width: $breakpoint-mobile) {
            font-size: .85em;
        }
    }

    .unitContainer {
        padding: 0.75em;
        background: rgba(255,255,255,0.60);
        border-radius: 3px;
        text-align: center;
    }

    .digit {
        display: inline-block;
        text-align: center;
        font-size: 32/18+em;
        font-weight: bold !important;
        line-height: 1;
        width: 20/30+em;
    }

    .label {
        display: block;
        font-size: 12/18+em;
        margin-top: 2/10+em;
        text-transform: uppercase;
        font-weight: 300 !important;
    }

    .separator {
        font-size: 30/18+em;
        line-height: .85;
    }
}
