:local {
  .wrapper {
    margin: 35/16 + em auto 0;
    max-width: 720px;
  }

  .description {
    line-height: 1.6;
    margin-bottom: 1em;
  }

  .alert {
    line-height: 1.6;
    padding: 2em;
    background-color: var(--alert-background-color);
    color: var(--alert-font-color);
    text-align: center;
  }

  .anonymousDonationWrapper {
    background: #f9f9f9;
    padding: 1em;
  }

  .paver {
    flex: 1 1 0;
    cursor: pointer;
    margin: 0 0.25em;
    min-width: 0;
    height: 120px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
      box-shadow: 0 0 6px var(--paver-background-color);
    }
  }

  .active {
    opacity: 1;
    box-shadow: 0 0 6px var(--paver-selected-background-color);
  }
}
