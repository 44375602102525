:local {
  .wrapper {
    background: #fff;
    border: 1px solid var(--border-color-wrapper);
    border-radius: 4/16 + em;
    margin: -3.5em auto -3.5em;
    max-width: 960px;
    padding: 3em 1em;
    position: relative;
    z-index: 1;

    @media all and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  .inner {
    margin: 0 auto;
    max-width: 720px;
  }

  .description {
    line-height: 1.6;
    margin-bottom: 1em;
  }
}
